import styled from "styled-components";

export const DownSection = styled.div`
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    justify-content: center;
    align-items: center;
    
    #DownMessage {
        font-weight: 500;
        font-size: 4vw;
        padding-bottom: 25px;
        pointer-events: none;
        text-transform: uppercase;
        text-align: center;
    } 

    #Text {
        font-weight: bold;
        font-size: 1.2rem;
        pointer-events: none;
    } 

    #CV{
        font-size: 25px;
        text-decoration: none;
        z-index: 100;
    }

    .buttons{
        display: flex;
    }

    @media ${props => props.theme.breakpoints.sm}{
        #DownMessage {
            font-size: 8vw;
        }

        #CV{
            font-size: 18px;
        }
    }

    .cv-button, .email-button{
        width: 150px;
        background-image: linear-gradient(to right, ${props => props.theme.colors.accent}, ${props => props.theme.colors.primary});
        padding: 2px !important;
        border-radius: 5px;
        margin: 10px;

        &:hover{
            filter: brightness(0.8);
        }

        div {
            padding: 8px;
            border-radius: 5px;
            text-align: center;
            background: ${props => props.theme.colors.background2} !important;
        }


        @media ${props => props.theme.breakpoints.sm}{
            width: 100px;
        }
    }
`;