import React from 'react';
import BackgroundAnimation from '../BackgroundAnimation/BackgroundAnimation';
import { DownSection} from './DownStyles';

const Down: React.FC = () => {
    return (
       <DownSection>
           <p id='DownMessage'>
                <span>Website Removed</span>
            </p>
            <div className="buttons">
                <a href="/Media/CV/Francois-Smith-CV.pdf" id="CV" target="_blank">
                    <div className="cv-button">
                        <div>
                            <span className="Text-Gradient">View CV</span>
                        </div>
                    </div>
                </a>
                <a href="mailto: contact@francois-smith.com" id="CV" target="_blank" rel="noreferrer">
                    <div className="email-button">
                        <div>
                            <span className="Text-Gradient">Contact</span>
                        </div>
                    </div>
                </a>
            </div>
           <BackgroundAnimation/>
       </DownSection >
    ); 
};

export default Down;