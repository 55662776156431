import styled from "styled-components";

export const Animation = styled.div`
    position: absolute;
    bottom: 0px;
    right: 0px;
    left: 0px;
    top: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;

    svg {
        height: 80%;
    } 
`;